import { FormHelperText, TextareaAutosize } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useFormContext, get } from 'react-hook-form';

export type TextAreaProps = {
  name: string;
  placeholder: string;
};

export const StyledTextArea = styled(TextareaAutosize)({
  backgroundColor: '#fff',
  display: 'block',
  width: '100%',
  height: '140px !important',
  maxWidth: '688px',
  border: '1px solid #D9DFEF',
  borderRadius: '10px',
  resize: 'none',
  padding: '14px 16px',
  color: '',
  fontFamily: 'Antebas, sans-serif',
  fontSize: '14px',

  '&:focus': {
    outline: 'none',
  },
});

const TextArea = ({ name, placeholder }: TextAreaProps) => {
  const {
    register,
    formState: { errors, isSubmitted },
  } = useFormContext();
  const errorMessage = get(errors, name)?.message as string;

  return (
    <>
      <StyledTextArea {...register(name)} placeholder={placeholder} />
      {isSubmitted && errorMessage && (
        <FormHelperText error>{errorMessage}</FormHelperText>
      )}
    </>
  );
};

export default TextArea;
