import { Button, Tooltip, useTheme } from '@mui/material';

import { useAppContext } from 'context/AppContext';
import MeetingRoomModal from 'components/Rooms/MeetingRoomModal';
import EditIcon from 'assets/icons/EditIcon';

import LoadingButton from 'components/Common/LoadingButton';
import { useAuth } from 'context/AuthContext';

type showButtonProps = {
  isBooked?: boolean;
  disabled?: boolean;
  bookingData?: any;
  bookRoom?: () => void;
  isHeader?: boolean;
  isLoading?: boolean;
  buttonOff?: boolean;
};

const ShowEditOrConfirmButton = ({
  isBooked,
  disabled,
  bookingData,
  isHeader,
  bookRoom,
  isLoading,
  buttonOff,
}: showButtonProps) => {
  const { openModal } = useAppContext();
  const { user, isAuthenticated } = useAuth();

  const theme = useTheme();

  if (isBooked)
    return (
      <Button
        variant="contained"
        color="secondary"
        disabled={disabled}
        sx={{
          justifyContent: 'space-between',
          color: theme.palette.secondary.contrastText,
          width: '168px',
          height: '44px',
          paddingLeft: '20px',
          '&:disabled': {
            color: theme.palette.secondary.contrastText,
          },
        }}
        endIcon={
          <EditIcon
            sx={{
              color: theme.palette.secondary.contrastText,
              marginRight: 1,
              width: '24px',
              height: '24px',
            }}
          />
        }
        onClick={() => {
          openModal(
            <MeetingRoomModal
              isBooked={isBooked}
              initialValues={
                bookingData && {
                  location: bookingData.location,
                  month: bookingData.month,
                  day: bookingData.day,
                  time: bookingData.time,
                  duration: bookingData.duration,
                  ...(bookingData.bookingId
                    ? { bookingId: bookingData.bookingId }
                    : {}),
                }
              }
            />,
          );
        }}
      >
        Edit booking
      </Button>
    );
  return (
    <Tooltip
      title={
        !isAuthenticated || user?.canMakeBookings
          ? null
          : 'You cannot make bookings. Contact with your Team Leader.'
      }
    >
      <span>
        <LoadingButton
          isLoading={!!isLoading}
          variant="contained"
          disabled={
            disabled || buttonOff || (isAuthenticated && !user?.canMakeBookings)
          }
          onClick={() => bookRoom?.()}
          sx={{
            minWidth: '158px',
            minHeight: '46px',
            '&.MuiButtonBase-root, &.MuiButtonBase-root:hover': {
              backgroundColor: isHeader
                ? theme.palette.secondary.main
                : theme.palette.primary.main,
              color: isHeader ? theme.palette.text.primary : '#fff',
            },
          }}
        >
          Confirm booking
        </LoadingButton>
      </span>
    </Tooltip>
  );
};

ShowEditOrConfirmButton.defaultProps = {
  isBooked: false,
  disabled: false,
  bookingData: {},
  isHeader: false,
  bookRoom: null,
  isLoading: false,
  buttonOff: false,
};

export default ShowEditOrConfirmButton;
