import { useMemo, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import routes from 'utils/routes';
import Footer from 'components/Footer';

import { STEPS } from 'constants/signUp';
import { getItem, KEYS } from 'utils/cache';
import NavigationDrawer from './Drawer';
import { Main, Grid, BannerWrapper } from './style';
import AnimatedBackground from './AnimatedBackground';
import UnsupportedBrowserBanner from './UnsupportedBrowserBanner';
import UnsupportedResolutionBanner from './UnsupportedResolutionBanner';
import SignUpLayout from '../Register/SignUpLayout';
import ImpersonationBanner from './ImpersonationBanner';

const onlyMainSectionRoutes = [
  routes.auth.signIn,
  routes.auth.resetPassword,
  routes.auth.doResetPassword(),
  routes.tourBooking,
  routes.auth.activation,
];
const shouldOverflowRoutes = [
  routes.main.account.profile.split('/')[1],
  routes.main.meetingsRoomsGallery().split('/')[1],
  routes.main.externalBookingsGallery().split('/')[1],
];

const Layout = ({ children }: { children: any }) => {
  const { pathname } = useLocation();
  const isSignUp = useMemo(
    () => !!STEPS.find((step) => step.route === pathname.replace(/\/$/, '')),
    [pathname],
  );

  const isPasswordReset = useMemo(
    () => !!pathname.match(/\/reset-password\/.*/),
    [pathname],
  );

  const isActivation = useMemo(
    () => !!pathname.match(/\/activation\/.*/),
    [pathname],
  );

  const onlyMainSection = useMemo(
    () =>
      onlyMainSectionRoutes.includes(pathname) ||
      isSignUp ||
      isPasswordReset ||
      isActivation,
    [pathname],
  );

  const shouldOverflow = useMemo(
    () => shouldOverflowRoutes.includes(pathname.split('/')[1]),
    [pathname],
  );

  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  const isImpersonationView = getItem(KEYS.IMPERSONATE_TOKEN);
  return (
    <>
      <Grid paddingTop={onlyMainSection ? '0' : '32px'}>
        {!onlyMainSection && <NavigationDrawer />}
        <Main
          id="main-container"
          onlyMainSection={onlyMainSection}
          shouldOverflow={shouldOverflow}
        >
          {onlyMainSection && <AnimatedBackground />}
          <BannerWrapper>
            <UnsupportedBrowserBanner />
            <UnsupportedResolutionBanner />
          </BannerWrapper>

          {isSignUp ? <SignUpLayout>{children}</SignUpLayout> : children}
        </Main>
      </Grid>
      {!onlyMainSection && <Footer />}
      {isImpersonationView && <ImpersonationBanner />}
    </>
  );
};
export default Layout;
