import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';

import { API, APIRoutes } from 'utils/api';
import { useToast } from 'context/ToastContext';
import { useAuth } from 'context/AuthContext';
import routes from 'utils/routes';
import { useAppContext } from 'context/AppContext';
import ConfirmationBox from 'components/Common/ConfirmationBox';
import { ActionButton } from './style';

export type AcceptPaymentButtonProps = {
  invoiceId: string;
  amount: string;
  invoiceNumber: string;
};

const AcceptPaymentButton = ({
  invoiceId,
  amount,
  invoiceNumber,
}: AcceptPaymentButtonProps) => {
  const { snack, setSnack } = useToast();
  const { openModal } = useAppContext();
  const navigate = useNavigate();
  const { user } = useAuth();

  const { mutate: payByCard, isLoading } = useMutation(
    () => API.post(APIRoutes.invoices.pay(invoiceId)),
    {
      onSuccess: (response) => {
        const { data: axiosData } = response;
        const { status, data } = axiosData;
        // console.log('STATUS: ', status);
        // console.log('DATA: ', data);
        if (status === 'success') {
          setSnack({
            ...snack,
            message: 'The invoice has been paid.',
            open: true,
            type: 'success',
          });
        } else if (status === 'pending') {
          const { action, value } = data;
          if (action === 'redirect') {
            window.location.href = value;
          }
        }
      },
      onError: (response: { message: string }) => {
        setSnack({
          ...snack,
          message:
            response.message ||
            'The invoice could not be paid. Please try again later.',
          open: true,
          type: 'error',
        });
      },
    },
  );

  return (
    <ActionButton
      isLoading={isLoading}
      onClick={() => {
        if (user?.cardNumber) {
          openModal(
            <ConfirmationBox
              text={`Pay invoice ${invoiceNumber} (${amount} GBP) using card ending in "${user?.cardNumber}"?`}
              onClick={payByCard}
            />,
          );
        } else {
          navigate(routes.main.account.creditCard);
        }
      }}
    >
      <PaymentOutlinedIcon />
    </ActionButton>
  );
};

export default AcceptPaymentButton;
