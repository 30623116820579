import { useEffect } from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash-es';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { Box, Typography, Grid } from '@mui/material';

import CalendarItem from 'components/Bookings/CalendarItem';
import { Title } from 'components/Title';
import Loader from 'components/Loader';
import BookingCard from 'components/Bookings/BookingCard';
import Section from 'components/Section';
import LoadingSpinner from 'components/Common/LoadingSpinner';

import ScrollTopButton from 'components/Common/ScrollTopButton';
import EmptySection from 'components/Common/EmptySection';
import MeetingRoomModal from 'components/Rooms/MeetingRoomModal';

import { API, APIRoutes } from 'utils/api';
import breakpoints from 'styles/theme/breakpoints';

import SadFace from 'assets/icons/SadFace';

import { useAppContext } from 'context/AppContext';
import HeaderSection from './ResultsViewHeader';

interface GalleryProps {
  changeLoc: (value: number) => void;
  loc: number | undefined;
  extendedGrid: boolean;
  setExtendedGrid: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Params {
  spaceId: number | undefined;
  capacity: number;
  from: string;
  to: string;
}

const getBookings = async () => {
  const {
    data: { data },
  } = await API.get(APIRoutes.bookings.my);
  return data;
};

const getRooms = async (params: Params) => {
  const {
    data: { data },
  } = await API.get(APIRoutes.bookings.filtered(params));
  return data;
};

const GridRoomsView = ({
  RoomsIsLoading,
  RoomsData,
  defaultParams,
  OneHourAdded,
  extendedGrid,
}: {
  RoomsIsLoading: boolean;
  RoomsData: Room[] | undefined;
  defaultParams: Params;
  OneHourAdded: string;
  extendedGrid: boolean;
}) => {
  const from = moment(defaultParams?.from).format('HH:mm');
  const { openModal } = useAppContext();

  if (RoomsIsLoading) {
    return <LoadingSpinner minHeight="150px" />;
  }

  if (isEmpty(RoomsData)) {
    return (
      <EmptySection
        missing={`there were no ${extendedGrid ? 'results' : 'rooms'} found`}
        label={extendedGrid ? 'Edit search' : 'Book a room'}
        helperText={
          extendedGrid ? 'Try adjusting your search' : 'Book a room today'
        }
        icon={<SadFace />}
        isEdit={extendedGrid}
        onClick={() => {
          openModal(
            <MeetingRoomModal
              initialValues={{
                location: defaultParams?.spaceId?.toString(),
              }}
            />,
          );
        }}
      />
    );
  }

  return (
    <Grid container spacing={2} mt={extendedGrid ? '0px' : '26px'}>
      {RoomsData?.filter((card) => !card.hidden).map((card: Room) => (
        <Grid
          item
          xs={extendedGrid ? 6 : 12}
          md={extendedGrid ? 4 : 6}
          key={card.id}
        >
          <BookingCard
            card={card}
            from={from}
            to={OneHourAdded}
            space={defaultParams?.spaceId}
            bookingInterval={{ from: defaultParams.from, to: defaultParams.to }}
            extendedGrid={extendedGrid}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const Gallery = ({
  changeLoc,
  loc,
  extendedGrid,
  setExtendedGrid,
}: GalleryProps) => {
  const [searchParams] = useSearchParams();
  const { openModal } = useAppContext();

  const OneHourAdded = moment()
    .add(1, 'hours')
    .set({
      minutes: moment().get('minutes') - (moment().get('minutes') % 15) + 15,
      seconds: 0,
      milliseconds: 0,
    });

  const paramsSpaceId = searchParams.get('spaceId') as string;
  const paramFrom = searchParams.get('from') as string;
  const paramTo = searchParams.get('to') as string;
  const paramCapacity = searchParams.get('capacity') as string;

  const defaultParams: Params = {
    spaceId: +paramsSpaceId,
    capacity: Number.isNaN(parseInt(paramCapacity, 10))
      ? 1
      : parseInt(paramCapacity, 10),
    from: paramFrom
      ? moment(paramFrom).toISOString()
      : moment()
          .set({
            minutes:
              moment().get('minutes') - (moment().get('minutes') % 15) + 15,
            seconds: 0,
            milliseconds: 0,
          })
          .toISOString(),

    to: paramTo ? moment(paramTo).toISOString() : OneHourAdded.toISOString(),
  };

  const {
    isLoading: BookingsIsLoading,
    data: bookingsData,
    isSuccess: isSuccessBookings,
  } = useQuery<BookingProps[]>('bookings', () => getBookings());

  const {
    isLoading: RoomsIsLoading,
    data: RoomsData,
    isSuccess,
  } = useQuery<Room[]>(
    [
      'rooms',
      [
        searchParams.get('spaceId'),
        searchParams.get('capacity'),
        searchParams.get('from'),
        searchParams.get('to'),
      ],
    ],
    () => getRooms(defaultParams),
    { enabled: !Number.isNaN(defaultParams.spaceId) },
  );

  useEffect(() => {
    if (isSuccess) {
      setExtendedGrid(searchParams.get('from') !== null);

      if (
        searchParams.get('from') !== null &&
        loc === parseInt(searchParams.get('spaceId') as string, 10)
      )
        changeLoc(parseInt(searchParams.get('spaceId') as string, 10));
    }
  }, [RoomsData]);

  useEffect(() => {
    if (extendedGrid)
      changeLoc(parseInt(searchParams.get('spaceId') as string, 10));
  }, [isSuccess]);

  if (BookingsIsLoading && RoomsIsLoading) {
    return <Loader />;
  }

  return (
    <Section>
      <Box
        sx={{
          display: 'flex',
          gap: '28px',
          [`@media (max-width: ${breakpoints.values.xl}px)`]: {
            display: 'block',
          },

          [`@media (max-width: ${breakpoints.values.md}px)`]: {
            marginTop: '64px',
          },
        }}
      >
        <>
          {!extendedGrid && (
            <Box maxWidth="379px" width="100%">
              <Title variant="h3">Upcoming bookings</Title>
              {(() => {
                if (isSuccess && isSuccessBookings) {
                  const bookings = bookingsData?.filter((booking) =>
                    moment(booking.checkIn).isSameOrAfter(moment()),
                  );
                  return (
                    <Box component="span">
                      {!bookings?.length ? (
                        <EmptySection
                          missing="you have no upcoming bookings"
                          label="Book a room"
                          helperText="Book a room today"
                          icon={<SadFace />}
                          onClick={() => {
                            openModal(
                              <MeetingRoomModal
                                setLocalization={changeLoc}
                                initialValues={{
                                  location: defaultParams?.spaceId?.toString(),
                                }}
                              />,
                            );
                          }}
                        />
                      ) : (
                        bookings?.map((el, index: number, arr: any[]) => {
                          const prevElementFromDate = moment(
                            arr[index - 1]?.checkIn,
                          );
                          const currentElFromDate = moment(el?.checkOut);

                          const isSameMonth = moment(
                            prevElementFromDate,
                          ).isSame(currentElFromDate, 'month');

                          return (
                            <Box component="span" key={el._id}>
                              {bookings.length > 0 && (
                                <Typography
                                  mt={isSameMonth ? 2 : 3}
                                  mb="6px"
                                  fontWeight="bold"
                                >
                                  {!isSameMonth
                                    ? currentElFromDate.format('MMMM')
                                    : ''}
                                </Typography>
                              )}
                              <CalendarItem
                                key={`upcoming_bookings-${el._id}`}
                                {...el}
                              />
                            </Box>
                          );
                        })
                      )}
                    </Box>
                  );
                }
                return <LoadingSpinner minHeight="150px" />;
              })()}
            </Box>
          )}
          <Box
            width="100%"
            sx={{
              [`@media (max-width: ${breakpoints.values.xl}px)`]: {
                marginTop: '32px',
              },
            }}
          >
            <HeaderSection
              date={defaultParams.from}
              extendedGrid={extendedGrid}
              loc={loc}
              changeLoc={changeLoc}
              element={
                <Title variant="h3" mb="27px">
                  Available right now
                </Title>
              }
            />

            <GridRoomsView
              RoomsIsLoading={RoomsIsLoading}
              RoomsData={RoomsData}
              OneHourAdded={
                searchParams.get('to') === null
                  ? OneHourAdded.format('HH:mm')
                  : moment(searchParams.get('to')).format('HH:mm')
              }
              defaultParams={defaultParams}
              extendedGrid={extendedGrid}
            />
          </Box>
        </>
      </Box>
      <Box textAlign="center" mt="27px">
        <ScrollTopButton />
      </Box>
    </Section>
  );
};
export default Gallery;
