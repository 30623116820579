import React, { ChangeEvent } from 'react';
import { TextField, FormControl, MenuItem, Typography } from '@mui/material';

export const CancellationReason = ({
  options,
  currentReason,
  setCurrentReason,
}: {
  options: {
    label: string;
    value: string;
  }[];
  currentReason: string;
  setCurrentReason: React.Dispatch<React.SetStateAction<string>>;
}) => (
  <FormControl
    sx={{
      width: 450,
      margin: 'auto',
    }}
  >
    <TextField
      label="Cancellation Reason"
      value={currentReason || ''}
      select
      required
      fullWidth
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        // console.log('Event', event);
        setCurrentReason(event.target.value);
      }}
    >
      {options.map((option: any) => (
        <MenuItem key={option.value} value={option.value}>
          <Typography fontSize="16px">{option.label}</Typography>
        </MenuItem>
      ))}
    </TextField>
  </FormControl>
);
