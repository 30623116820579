/* eslint-disable max-len */
import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import routes from 'utils/routes';

import ShowEditOrConfirmButton from 'components/Bookings/ShowEditOrConfirmButton';
import DiscountForm from 'pages/ExternalMeetingRooms/DiscountForm';
import InfoIcon from 'assets/icons/InfoIcon';

type BookingPricingProps = {
  isBooked: boolean | undefined;
  fullPrice: BookingFullPrice;
  disabled: boolean;
  bookingData?: Query;
  isLoading?: boolean;
  buttonOff?: boolean;
  bookRoom?: () => void;
  setDiscount?: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  discountCode?: DiscountCode;
};

const BookingPricing = ({
  isBooked,
  fullPrice,
  disabled,
  bookingData,
  isLoading,
  buttonOff,
  bookRoom,
  setDiscount,
  discountCode,
}: BookingPricingProps) => {
  const { pathname } = useLocation();

  const isExternalBookings =
    pathname.split('/')[1] ===
    routes.main.externalBookingsReview().split('/')[1];

  return (
    <Box sx={{ padding: 3 }}>
      <Stack fontSize={26}>
        <Box display="flex" justifyContent="space-between" gap={2}>
          <Typography fontSize="inherit" flex={2}>
            Price of booking (full price)
          </Typography>
          <Typography
            fontSize="inherit"
            fontWeight="fontWeightBold"
            width="200px"
          >
            £{fullPrice.priceOfBooking.toFixed(2)}
          </Typography>
        </Box>

        <Box display="flex" justifyContent="space-between" gap={2}>
          <Typography fontSize="inherit" flex={2}>
            VAT (full price)
          </Typography>
          <Typography
            fontSize="inherit"
            fontWeight="fontWeightBold"
            width="200px"
          >
            £{fullPrice.taxAmount.toFixed(2)}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" gap={2}>
          <Typography fontSize="inherit" fontWeight="fontWeightBold" flex={2}>
            Total
          </Typography>
          <Box width="200px">
            <Box
              fontSize={26}
              fontWeight="fontWeightBold"
              display="flex"
              alignItems="center"
              gap={1}
            >
              £{fullPrice.amountToPay.toFixed(2)}
              <Tooltip title="Credits are redeemed first, and if availible, at the time of the meeting room booking. This does not reflect any other booked rooms. This will be refelected on the subsequent invoice.">
                <IconButton
                  color="inherit"
                  sx={{
                    padding: 0,
                  }}
                >
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Stack>

      <Divider
        sx={{
          margin: '8px 0',
          border: '1px solid #D9DFEF',
        }}
      />

      <Stack fontSize={26} mb={2}>
        <Box display="flex" justifyContent="space-between" gap={2}>
          <Typography fontSize="inherit" flex={2}>
            Member discount
          </Typography>
          <Box
            fontSize="inherit"
            fontWeight="fontWeightBold"
            display="flex"
            alignItems="center"
            gap={1}
            width="200px"
          >
            {fullPrice.userDiscount}%
            <Tooltip title="This is the discount applied to any cost after credits are applied">
              <IconButton
                color="inherit"
                sx={{
                  padding: 0,
                }}
              >
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between" gap={2}>
          <Typography fontSize="inherit" flex={1}>
            Discount total
          </Typography>
          <Typography
            fontSize="inherit"
            fontWeight="fontWeightBold"
            width="200px"
          >
            £{fullPrice.discountTotal.toFixed(2)}
          </Typography>
        </Box>
      </Stack>
      {!isBooked && isExternalBookings && setDiscount && (
        <DiscountForm
          setDiscount={setDiscount}
          discountCode={discountCode}
          disabled={disabled}
        />
      )}
      <ShowEditOrConfirmButton
        isBooked={isBooked}
        disabled={disabled}
        bookingData={bookingData && bookingData}
        isLoading={isLoading}
        buttonOff={buttonOff}
        bookRoom={bookRoom}
      />
    </Box>
  );
};

BookingPricing.defaultProps = {
  bookingData: {},
  isLoading: false,
  buttonOff: false,
  bookRoom: null,
  setDiscount: null,
  discountCode: {},
};

export default BookingPricing;
