import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
} from 'chart.js';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import { OPTIONS } from 'constants/metrics';

export type ChartProps = {
  data: {
    monthDate: string;
    invoicedAmount: number;
  }[];
};

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

const options = {
  ...OPTIONS,
  plugins: {
    ...OPTIONS.plugins,
    tooltip: {
      ...OPTIONS.plugins.tooltip,
      callbacks: {
        label: (context: any) =>
          `${context.dataset.label}: £${context.parsed.y.toFixed(2)}`,
      },
    },
  },
};

const MetricChart = ({ data }: { data: interfaceMetridData[] }) => {
  const dataset = {
    labels: data.map((item) => moment(item.monthDate).format('MMM YY')),
    datasets: [
      {
        data: data.map((item) => item.invoicedAmount),
        label: 'Me',
        backgroundColor: '#1D1954',
        borderRadius: 24,
        borderSkipped: false,
        barThickness: 32,
      },
    ],
    maintainAspectRatio: false,
  };

  return <Bar options={options} data={dataset} />;
};

export default MetricChart;
