const STORAGE_KEY_PREFFIX = 'wl';

export enum KEYS {
  AUTH_TOKEN = 'authToken',
  IMPERSONATE_TOKEN = 'impersonateToken',
  KEEP_LOGGED_IN = 'keepLoggedIn',
  CURRENT_SPACE_ID = 'currentSpaceId',
  REFRESH_TOKEN = 'refreshToken',
  IGNORE_UNSUPPORTED_BROWSER = 'ignoreUnsupportedBrowser',
  IGNORE_UNSUPPORTED_RESOLUTION = 'ignoreUnsupportedResolution',
  FAVOURITES = 'favourites',
  IS_DRAWER_EXPANDED = 'is_drawer_expanded',
  SIGNUP_START_DATE = 'startDate',
  INDUCTION_DATE = 'inductionDate',
  INDUCTION_TIME = 'inductionTime',
}

const prepareKey = (key: ValueOf<KEYS>) => `${STORAGE_KEY_PREFFIX}::${key}`;

export const setItem = (key: ValueOf<KEYS>, value: string): void => {
  if (typeof window !== 'undefined') {
    localStorage.setItem(prepareKey(key), value);
  }
};

export const getItem = (key: ValueOf<KEYS>): string | null => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(prepareKey(key));
  }

  return null;
};

export const removeItem = (key: ValueOf<KEYS>): void => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem(prepareKey(key));
  }
};

export const clearCache = (): void => {
  if (typeof window !== 'undefined') {
    Object.keys(KEYS).forEach((key: ValueOf<KEYS>) => removeItem(key));
  }
};
