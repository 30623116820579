import { useEffect } from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash-es';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';

import Loader from 'components/Loader';
import ExternalBookingCard from 'components/Bookings/ExternalBookingCard';
import Section from 'components/Section';
import LoadingSpinner from 'components/Common/LoadingSpinner';

import ScrollTopButton from 'components/Common/ScrollTopButton';
import EmptySection from 'components/Common/EmptySection';
import MeetingRoomModal from 'components/Rooms/MeetingRoomModal';

import { API, APIRoutes } from 'utils/api';
import breakpoints from 'styles/theme/breakpoints';

import SadFace from 'assets/icons/SadFace';

import { useAppContext } from 'context/AppContext';
import theme from 'styles/theme';
import HeaderSection from './ResultsViewHeader';

interface GalleryProps {
  changeLoc: (value: number) => void;
  loc: number | undefined;
  extendedGrid: boolean;
  setExtendedGrid: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Params {
  spaceId: number | undefined;
  capacity: number;
  from: string;
  to: string;
  roomId?: number | undefined;
}

const getRooms = async (params: Params) => {
  const {
    data: { data },
  } = await API.get(APIRoutes.bookings.external(params));
  return data;
};

const GridRoomsView = ({
  RoomsIsLoading,
  RoomsData,
  defaultParams,
  OneHourAdded,
  extendedGrid,
}: {
  RoomsIsLoading: boolean;
  RoomsData: Room[] | undefined;
  defaultParams: Params;
  OneHourAdded: string;
  extendedGrid: boolean;
}) => {
  const from = moment(defaultParams?.from).format('HH:mm');
  const { openModal } = useAppContext();

  if (RoomsIsLoading) {
    return <LoadingSpinner minHeight="150px" />;
  }

  if (isEmpty(RoomsData)) {
    return (
      <EmptySection
        missing={`there were no ${extendedGrid ? 'results' : 'rooms'} found`}
        label={extendedGrid ? 'Edit search' : 'Book a room'}
        helperText={
          extendedGrid ? 'Try adjusting your search' : 'Book a room today'
        }
        icon={<SadFace />}
        isEdit={extendedGrid}
        onClick={() => {
          openModal(
            <MeetingRoomModal
              initialValues={{
                location: defaultParams?.spaceId?.toString(),
              }}
            />,
          );
        }}
      />
    );
  }

  return (
    <Grid container spacing={2} mt={extendedGrid ? '0px' : '26px'}>
      {RoomsData?.filter((card) => !card.hidden).map((card: Room) => (
        <Grid
          item
          xs={extendedGrid ? 6 : 12}
          md={extendedGrid ? 4 : 6}
          key={card.id}
        >
          <ExternalBookingCard
            card={card}
            from={from}
            to={OneHourAdded}
            space={defaultParams?.spaceId}
            bookingInterval={{ from: defaultParams.from, to: defaultParams.to }}
            extendedGrid={extendedGrid}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const ExternalGallery = ({
  changeLoc,
  loc,
  extendedGrid,
  setExtendedGrid,
}: GalleryProps) => {
  const [searchParams] = useSearchParams();

  const OneHourAdded = moment()
    .add(1, 'hours')
    .set({
      minutes: moment().get('minutes') - (moment().get('minutes') % 15) + 15,
      seconds: 0,
      milliseconds: 0,
    });

  const paramsSpaceId = searchParams.get('spaceId') as string;
  const paramFrom = searchParams.get('from') as string;
  const paramTo = searchParams.get('to') as string;
  const paramCapacity = searchParams.get('capacity') as string;
  const paramRoomId = searchParams.get('roomId') as string;

  const defaultParams: Params = {
    spaceId: +paramsSpaceId,
    capacity: Number.isNaN(parseInt(paramCapacity, 10))
      ? 1
      : parseInt(paramCapacity, 10),
    from: paramFrom
      ? moment(paramFrom).toISOString()
      : moment()
          .set({
            minutes:
              moment().get('minutes') - (moment().get('minutes') % 15) + 15,
            seconds: 0,
            milliseconds: 0,
          })
          .toISOString(),

    to: paramTo ? moment(paramTo).toISOString() : OneHourAdded.toISOString(),
    roomId: +paramRoomId,
  };

  const {
    isLoading: RoomsIsLoading,
    data: RoomsData,
    isSuccess,
  } = useQuery<Room[]>(
    [
      'rooms',
      [
        searchParams.get('spaceId'),
        searchParams.get('capacity'),
        searchParams.get('from'),
        searchParams.get('to'),
        searchParams.get('roomId'),
      ],
    ],
    () => getRooms(defaultParams),
    { enabled: !Number.isNaN(defaultParams.spaceId) },
  );

  useEffect(() => {
    if (isSuccess) {
      setExtendedGrid(searchParams.get('from') !== null);

      if (
        searchParams.get('from') !== null &&
        loc === parseInt(searchParams.get('spaceId') as string, 10)
      )
        changeLoc(parseInt(searchParams.get('spaceId') as string, 10));
    }
  }, [RoomsData]);

  useEffect(() => {
    if (extendedGrid)
      changeLoc(parseInt(searchParams.get('spaceId') as string, 10));
  }, [isSuccess]);

  if (RoomsIsLoading) {
    return <Loader />;
  }

  if (moment(defaultParams.from).isBetween('2023-12-25', '2024-01-01')) {
    return (
      <Box
        width="100%"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
        color={theme.palette.text.content}
      >
        <Box sx={{ svg: { width: '100%', height: '100%' } }} mt="70px">
          <SadFace />
        </Box>
        <Typography
          fontWeight="400"
          fontSize="20px"
          mt={1}
          lineHeight="20px"
          maxWidth="600px"
        >
          We’re sorry, but bookings are unavailable for the festive period.
          Please search for a booking after the 1st of January 2024.
        </Typography>
      </Box>
    );
  }

  if (moment(defaultParams.from).isBetween('2024-04-12', '2024-04-13')) {
    return (
      <Box
        width="100%"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
        color={theme.palette.text.content}
      >
        <Box sx={{ svg: { width: '100%', height: '100%' } }} mt="70px">
          <SadFace />
        </Box>
        <Typography
          fontWeight="400"
          fontSize="20px"
          mt={1}
          lineHeight="20px"
          maxWidth="600px"
        >
          We’re sorry, but bookings are unavailable on April 12th.
        </Typography>
      </Box>
    );
  }

  return (
    <Section>
      <Box
        sx={{
          display: 'flex',
          gap: '28px',
          [`@media (max-width: ${breakpoints.values.xl}px)`]: {
            display: 'block',
          },

          [`@media (max-width: ${breakpoints.values.md}px)`]: {
            marginTop: '64px',
          },
        }}
      >
        <Box
          width="100%"
          sx={{
            [`@media (max-width: ${breakpoints.values.xl}px)`]: {
              marginTop: '32px',
            },
          }}
        >
          <HeaderSection
            date={defaultParams.from}
            loc={loc}
            changeLoc={changeLoc}
          />

          <GridRoomsView
            RoomsIsLoading={RoomsIsLoading}
            RoomsData={RoomsData}
            OneHourAdded={
              searchParams.get('to') === null
                ? OneHourAdded.format('HH:mm')
                : moment(searchParams.get('to')).format('HH:mm')
            }
            defaultParams={defaultParams}
            extendedGrid={extendedGrid}
          />
        </Box>
      </Box>
      <Box textAlign="center" mt="27px">
        <ScrollTopButton />
      </Box>
    </Section>
  );
};
export default ExternalGallery;
