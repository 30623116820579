import { Chip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ChipButton = styled(Chip)({
  width: '148px',
  height: '30px',
  border: '1px solid #FABE79',
  borderRadius: '24px',
  cursor: 'pointer',
  fontSize: '16px',
  fontWeight: '300',
  '& .MuiChip-label': {
    color: '#1D1954',
    fontSize: '16px',
  },
  '&:hover': {
    span: {
      color: '#FABE79',
    },
  },
});

export const HeaderLink = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  height: '100%',
  lineHeight: '56px',
  '&.active-scroll-spy': {
    fontWeight: theme.typography.fontWeightBold,
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
  },
}));
