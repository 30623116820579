/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useMemo, useState } from 'react';
import {
  Box,
  Button,
  Link,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Navigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import TagManager from 'react-gtm-module';

import { useQuery, useMutation } from 'react-query';
import { PLAN } from 'constants/plans';
import ReadMoreModal from 'components/SignUp/ReadMoreModal';
import { useAppContext } from 'context/AppContext';
import DatePickerController from 'components/Form/DatePickerController';
import routes from 'utils/routes';
import CheckBox from 'components/Form/CheckBox';
import { KEYS, getItem } from 'utils/cache';
import TextInput from 'components/Form/TextInput';
import { API, APIRoutes } from 'utils/api';
import Loader from 'components/Loader';
import { useToast } from 'context/ToastContext';

const schema = yup.object({
  startDate: yup.string().required('Start date is required.'),
  termsCheck: yup
    .boolean()
    .required('The terms and conditions must be accepted.')
    .oneOf([true], 'The terms and conditions must be accepted.'),
});

type FormData = yup.InferType<typeof schema>;

const ChosenPlanForm = () => {
  const theme = useTheme();
  const { openModal } = useAppContext();
  const { snack, setSnack } = useToast();
  const [searchParams] = useSearchParams();
  const tariffGuid = searchParams.get('tariff_guid') || Object.keys(PLAN)[0];
  const [discountCode, setDiscountCode] = useState('');
  const startDate = getItem(KEYS.INDUCTION_DATE);

  if (!tariffGuid) {
    return <Navigate to={routes.auth.signUp.index({ tariffGuid })} />;
  }

  const getPlan = async () => {
    const payload = { tariffGuid, discountCode, startDate };
    const {
      data: { data },
    } = await API.post(APIRoutes.auth.plan, payload);
    return data;
  };

  const {
    isFetching,
    isSuccess,
    data: plan,
    refetch,
  } = useQuery(['plan'], () => getPlan());

  const getBankHolidays = async () => {
    const {
      data: { data },
    } = await API.get(APIRoutes.bankHolidays);
    return data;
  };

  const {
    isLoading: isLoadingBankHolidays,
    isSuccess: bankHolidaysSuccess,
    data: bankHolidays,
  } = useQuery<string[]>('bankHolidays', () => getBankHolidays());

  const { mutate: goToPayment } = useMutation(
    (payload: any) => API.post(APIRoutes.auth.signUpPayment, payload),
    {
      mutationKey: 'sign-up-plan',
      onSuccess: (data) => {
        const { data: resData } = data;
        TagManager.dataLayer({
          dataLayer: {
            event:
              plan?.name === 'Flex' ? 'flexFormSubmit' : 'unlimitedFormSubmit',
          },
        });
        window.location.href = resData?.url;
      },
      onError: () => {
        setSnack({
          ...snack,
          message: 'Something went wrong. Please try again.',
          open: true,
          type: 'error',
        });
      },
    },
  );

  const initDate = useMemo(() => {
    if (startDate !== null) return moment(startDate).toISOString();
    const today = moment.utc().startOf('day');
    let daysRemaining = 1;

    while (daysRemaining > 0) {
      today.add(1, 'day');
      if (!(today.isoWeekday() === 6 || today.isoWeekday() === 7)) {
        daysRemaining -= 1;
      }
    }
    return today.toISOString();
  }, []);

  const methods = useForm<FormData>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      startDate: initDate,
    },
  });
  const { handleSubmit } = methods;

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    const payload = {
      ...data,
      tariffGuid,
      tariffId: plan.id,
      redirectUrl: `${window.location.origin}/sign-up/summary`,
      cancelUrl: window.location.href,
      discountCode: plan.discount?.isCodeValid ? discountCode : '',
    };
    goToPayment(payload);
  };

  if (isFetching || isLoadingBankHolidays) {
    return <Loader />;
  }

  if (isSuccess && bankHolidaysSuccess) {
    const finalPrice = plan.discount
      ? plan?.discount?.priceAfterDiscount
      : plan?.priceDecimal;

    return (
      <FormProvider {...methods}>
        <form id="step-2" onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              borderRadius: '10px',
              background: '#f1f1f1',
            }}
            p={4}
            mt={4}
            mx="auto"
            width={{ xs: '100%', lg: '760px' }}
          >
            <Stack spacing={2}>
              <Typography variant="h3" textTransform="uppercase">
                Your chosen plan
              </Typography>
              <Typography>
                {plan?.name} Membership
                <br />
                {plan?.name === 'Unlimited' && '£350 – Monthly '}
              </Typography>
              {plan?.name === 'Flex' && (
                <Box>
                  <Typography
                    fontSize={theme.typography.smallFontSize}
                    sx={{ opacity: 0.6 }}
                  >
                    £20 monthly minimum spend
                  </Typography>
                  <Typography
                    fontSize={theme.typography.smallFontSize}
                    sx={{ opacity: 0.6 }}
                  >
                    £25 one off set-up fee{' '}
                  </Typography>
                </Box>
              )}

              <Box>
                <Typography
                  fontSize={theme.typography.smallFontSize}
                  sx={{ opacity: 0.6 }}
                >
                  {plan?.name === 'Flex'
                    ? 'Pay-as-you-go coworking.'
                    : 'Unlimited coworking – Monthly'}
                </Typography>
              </Box>
              {/* eslint-disable jsx-a11y/anchor-is-valid */}
              <Link
                onClick={() =>
                  openModal(
                    <ReadMoreModal
                      planName={plan?.name}
                      description={plan?.description}
                    />,
                  )
                }
              >
                Read more
              </Link>
              <Box display="flex" gap={2} alignItems="center">
                <TextField
                  value={discountCode}
                  onChange={(e) => setDiscountCode(e.target.value)}
                  type="text"
                  variant="filled"
                  name="discountCode"
                  label="Discount code"
                  fullWidth
                />
                <Button
                  disabled={!discountCode}
                  sx={{
                    width: 'fit-content',
                    borderRadius: '10px',
                    padding: '13px 15px',
                    maxHeight: '56px',
                    backgroundColor: theme.palette.primary.main,
                  }}
                  variant="contained"
                  onClick={() => refetch()}
                >
                  Apply
                </Button>
              </Box>
              {plan.discount && (
                <Typography
                  fontWeight={700}
                  color={
                    plan.discount.isCodeValid ? 'success.main' : 'error.main'
                  }
                >
                  {plan.discount.isCodeValid
                    ? 'Discount code applied'
                    : 'Invalid discount code'}
                </Typography>
              )}
              <Typography
                width="fit-content"
                fontWeight={theme.typography.fontWeightBold}
                borderBottom={`2px solid ${theme.palette.orange[800]}`}
              >
                Today you’ll pay:&nbsp;£
                {finalPrice?.toFixed(2)}
                &nbsp;+&nbsp;VAT&nbsp;
                {plan?.name !== 'Flex' &&
                  `(pro-rated - final amount calculated at next step)`}
              </Typography>
              <Box>
                <Typography fontWeight={theme.typography.fontWeightBold} mb={1}>
                  {startDate === null
                    ? 'Choose a start date'
                    : 'Your chosen start date'}
                </Typography>
                {startDate === null ? (
                  <DatePickerController
                    name="startDate"
                    label="Start date"
                    disablePast
                    disableWeekends
                    bankHolidays={bankHolidays}
                  />
                ) : (
                  <TextInput
                    disabled
                    value={moment(startDate).format('DD/MM/YYYY')}
                    type="text"
                    label="Start date"
                    name="startDate"
                    variant="filled"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                )}
              </Box>
              <Box>
                <Typography fontWeight={theme.typography.fontWeightBold} mb={1}>
                  Your chosen induction location:
                </Typography>
                <TextInput
                  label="Select the space you'd like to your induction"
                  name="inductionSpaceId"
                  type="text"
                  value={plan?.inductionPlace}
                  required
                  disabled
                />
              </Box>
              <CheckBox
                label={
                  <>
                    I agree to these&nbsp;
                    <span onClick={(e) => e.stopPropagation()}>
                      <Link
                        href="https://work.life/memberterms/"
                        target="_blank"
                      >
                        terms and conditions
                      </Link>
                    </span>
                  </>
                }
                name="termsCheck"
              />
            </Stack>
          </Box>
        </form>
      </FormProvider>
    );
  }
  return null;
};

export default ChosenPlanForm;
