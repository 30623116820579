/* eslint-disable max-len */
import routes from 'utils/routes';

export const FAQ = [
  {
    id: 0,
    summary: 'How do I login to the Wifi?',
    details:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla diam tellus, facilisis at ultrices vitae, cursus ut libero. Etiam volutpat finibus lorem ac malesuada. Duis vel feugiat nisl. Donec faucibus porta lectus non commodo. Quisque tincidunt neque eget dolor maximus, at laoreet massa suscipit. Aliquam semper posuere mi a vulputate. Donec quis ullamcorper ligula. Nulla commodo fermentum quam sit amet blandit. Duis facilisis, ante sit amet tempor convallis, justo felis condimentum magna, eget malesuada sem orci a turpis. Maecenas congue tristique mauris. Fusce et metus dapibus, volutpat quam vitae, congue justo. Nullam accumsan elementum sem, non vestibulum urna interdum id. Morbi aliquam blandit nibh eu consectetur. ',
  },
  {
    id: 1,
    summary: 'How do I login to the Wifi?',
    details:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla diam tellus, facilisis at ultrices vitae, cursus ut libero. Etiam volutpat finibus lorem ac malesuada. Duis vel feugiat nisl. Donec faucibus porta lectus non commodo. Quisque tincidunt neque eget dolor maximus, at laoreet massa suscipit. Aliquam semper posuere mi a vulputate. Donec quis ullamcorper ligula. Nulla commodo fermentum quam sit amet blandit. Duis facilisis, ante sit amet tempor convallis, justo felis condimentum magna, eget malesuada sem orci a turpis. Maecenas congue tristique mauris. Fusce et metus dapibus, volutpat quam vitae, congue justo. Nullam accumsan elementum sem, non vestibulum urna interdum id. Morbi aliquam blandit nibh eu consectetur. ',
  },
];

export const ACTIONS = [
  {
    id: 0,
    title: 'Raise a ticket',
    description:
      'Raise a ticket with your membership team for issues with facilities, bookings, IT or membership.',
    image: '/assets/images/Messages.svg',
    label: 'Raise a ticket',
    link: routes.main.help.raiseTicket,
  },
  // {
  //   id: 1,
  //   title: 'Report a problem',
  //   description:
  //     'If your issue is non tech-related, conatct the membership team via this wall. We will tackle the problem as soon as possible and let you know via this wall.',
  //   image: '/assets/images/Report.svg',
  //   label: 'Ask for help',
  //   link: routes.main.help.ask,
  // },
];

export const FORM_OPTIONS = {
  topic: [
    'Maintenance',
    'Cleaning',
    'Security',
    'IT',
    'Office Management',
    'App',
    'Door Access',
    'Other',
  ],
  location: [
    'Bermondsey',
    'Borough',
    'Camden',
    'Farringdon',
    'Fitzrovia',
    'Hammersmith',
    'Holborn',
    'Liverpool Street',
    'Old Street',
    'Soho',
    'Reading',
    'Manchester',
  ],
  urgency: ['Low', 'Medium', 'High', 'Emergency'],
  colors: ['#68CA3D', '#FEDA8F', '#FABE79', '#F76666'],
};

export const DEFAULT_VALUES = { topic: '', location: '', urgency: '' };
export const IMAGE_PICKER_CONFIG = {
  maxFiles: 1,
  multiple: false,
  maxSize: 10485760,
  accept: {
    'image/jpg': [],
    'image/jpeg': [],
    'image/png': [],
  },
  errors: {
    'too-many-files': 'You can upload up to 3 images.',
    'file-too-large': `Files shouldn't be larger than 10mb`,
  },
};

export const SUPPORT_CONTACT = '02034110089';
