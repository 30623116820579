import { Stack } from '@mui/material';
import { useNavigate, useMatch } from 'react-router-dom';
import { useAuth } from 'context/AuthContext';

import {
  NAVIGATION_LIST_FOOTER,
  SOCIAL_MEDIA_LIST,
} from 'constants/navigationFooter';
import routes from 'utils/routes';
import { useAppContext } from 'context/AppContext';
import ConfirmationBox from 'components/Common/ConfirmationBox';
import {
  Wrapper,
  Container,
  Row,
  FooterLink,
  SocialMedias,
  SocialMedia,
  CopyrightText,
  Logo,
  CertifiedCorporation,
} from './style';

function Footer() {
  interface FooterSectionEl {
    label: string;
    href: string;
  }
  interface FooterSections {
    secondSection: FooterSectionEl[];
    firstSection: FooterSectionEl[];
    thirdSection: FooterSectionEl[];
  }
  const navigate = useNavigate();
  const isDashboard = useMatch(routes.main.dashboard);

  const { openModal } = useAppContext();
  const { logout, user, currentSpaceId, isBasketEmpty, isFromLondon } =
    useAuth();

  const handleClick = (item: { label: string; href: string }) => {
    if (item.label === 'Logout')
      return openModal(
        <ConfirmationBox
          text={
            isBasketEmpty
              ? 'Are you sure you want to logout?'
              : 'You have items in your basket, are you sure you want to logout?'
          }
          onClick={logout}
        />,
      );
    if (item.label === 'Meeting rooms')
      return navigate(
        routes.main.meetingsRoomsGallery({
          spaceId:
            currentSpaceId === 0 ? user?.spaceId.toString() : currentSpaceId,
        }),
      );
    if (item.label === 'Desk availability' && !isFromLondon)
      return navigate(routes.main.desks.charts);
    if (item.label === 'Terms & Conditions')
      return window.open('https://work.life/memberterms/', '_blank');
    if (item.label === 'Cookie policy')
      return window.open('https://work.life/cookies/', '_blank');
    if (item.label === 'Sustainability Data')
      return window.open('/data-sharing.pdf', '_blank');

    return navigate(item.href);
  };

  return (
    <Wrapper>
      <Container>
        <Row>
          <Logo
            src="/logo.svg"
            alt="Work Life Logo"
            onClick={() =>
              isDashboard
                ? window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                  })
                : navigate(routes.main.dashboard)
            }
            sx={{ cursor: 'pointer' }}
          />

          {Object.keys(NAVIGATION_LIST_FOOTER).map((key) => (
            <Stack key={key}>
              {NAVIGATION_LIST_FOOTER[key as keyof FooterSections].map((el) => (
                <FooterLink key={el.label} onClick={() => handleClick(el)}>
                  {el.label}
                </FooterLink>
              ))}
            </Stack>
          ))}
          <Stack>
            <SocialMedias>
              {SOCIAL_MEDIA_LIST.map((el) => (
                <SocialMedia
                  key={el.label}
                  href={el.href}
                  title={el.label}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {el.icon}
                </SocialMedia>
              ))}
            </SocialMedias>
            <CertifiedCorporation
              src="/assets/images/CertifiedCorporation.svg"
              width={52}
              height={86}
              alt="Certified Corporation"
            />
            <CopyrightText>
              Copyright &copy; Work.Life 2022. All rights reserved.
            </CopyrightText>
          </Stack>
        </Row>
      </Container>
    </Wrapper>
  );
}
export default Footer;
