import {
  CardContent,
  Typography,
  CardActionArea,
  Box,
  CardMedia,
  useTheme,
} from '@mui/material';

import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import LoadingButton from 'components/Common/LoadingButton';
import AccountIcon from 'assets/icons/AccountIcon';
import MotionCard from 'components/MotionCard';
import routes from 'utils/routes';

interface BookingCard {
  card: Room;
  to?: string;
  from?: string;
  extendedGrid?: boolean;
  bookingInterval: {
    from: string;
    to: string;
  };
  space: any;
}

const BookingCard = ({
  card,
  from,
  to,
  bookingInterval,
  extendedGrid,
  space,
}: BookingCard) => {
  const [searchParams] = useSearchParams();

  const theme = useTheme();

  const navigate = useNavigate();

  return (
    <MotionCard
      whileHover={{ scale: 1.025 }}
      sx={{
        height: '100%',
      }}
    >
      <CardActionArea
        component={Link}
        to={routes.main.externalBookingsReview(
          card.id,
          space,
          bookingInterval?.from,
          bookingInterval?.to,
        )}
        state={{
          capacity: searchParams.get('capacity'),
          returnToDefaultView: !extendedGrid,
        }}
        sx={{ height: '100%' }}
      >
        <CardContent
          sx={{
            padding: '16px 16px 24px 16px',
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
          }}
        >
          <Box mb="auto" width="100%">
            <CardMedia
              component="img"
              height="176"
              src={card.photo?.default.url}
              onError={(
                event: React.SyntheticEvent<HTMLImageElement, Event>,
              ) => {
                event.currentTarget.src = '/assets/images/placeholder.png';
              }}
              alt={card.name}
              sx={{ borderRadius: '4px', mb: 2 }}
            />
            <Box display="flex" justifyContent="space-between" gap="8px">
              <Box>
                <Typography
                  variant="h4"
                  fontWeight="fontWeightBold"
                  lineHeight="18px"
                  marginBottom="5px"
                >
                  {card.name}
                </Typography>
                <Typography
                  fontSize="smallFontSize"
                  sx={{
                    opacity: 0.65,
                  }}
                >
                  {card.district}
                </Typography>
              </Box>
              {card?.additionalInfo?.capacity && (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <AccountIcon
                    sx={{
                      width: '24px',
                      height: '24px',
                    }}
                  />
                  <Typography
                    fontWeight="fontWeightBold"
                    lineHeight="18px"
                    textAlign="center"
                  >
                    {card.additionalInfo.capacity}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>

          <Typography typography="18px">
            {from} - {to}
          </Typography>
          <Box component="span" sx={{ alignSelf: 'end' }}>
            <LoadingButton
              isLoading={false}
              variant="contained"
              sx={{
                backgroundColor: theme.palette.primary.main,
                height: '48px',
                padding: '12px 34px',
                width: '164px',
              }}
              onClick={(e) => {
                e.preventDefault();
                navigate(
                  routes.main.externalBookingsReview(
                    card.id,
                    space,
                    bookingInterval?.from,
                    bookingInterval?.to,
                  ),
                );
              }}
            >
              Instant book
            </LoadingButton>
          </Box>
        </CardContent>
      </CardActionArea>
    </MotionCard>
  );
};

BookingCard.defaultProps = {
  to: '',
  from: '',
  extendedGrid: false,
};

export default BookingCard;
