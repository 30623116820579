/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, Link } from '@mui/material';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useMutation, useQueryClient } from 'react-query';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import DetailsForm from 'components/Profile/DetailsForm';
import AddressForm from 'components/Profile/AddressForm';
import TellUsMoreForm from 'components/Register/TellUsMoreForm';
import PasswordForm from 'components/Register/PasswordForm';
import routes from 'utils/routes';
import CheckBox from 'components/Form/CheckBox';
import { API, APIRoutes, setAuthenticationToken } from 'utils/api';
import { KEYS, setItem } from 'utils/cache';
import { useToast } from 'context/ToastContext';
import SectionHeader from 'components/SectionHeader';
import Section from 'components/Section';
import LoadingButton from 'components/Common/LoadingButton';
import { useAuth } from 'context/AuthContext';

type FormData = {
  fullName: string | undefined;
  phone: string | undefined;
  email: string | undefined;
  dateOfBirth: Date | undefined;
  salutation: string | undefined;
  address: string | undefined;
  postCode: string | undefined;
  countryId: string | undefined;
  companyName: string | undefined;
  cityName: string | undefined;
  password: string | undefined;
  passwordConfirmation: string | undefined;
  tellUsMore: string | undefined;
  inductionSpaceId: number | undefined;
  generalTermsAcceptedOnline: boolean | undefined;
};

const wrapperSchema = () =>
  yup
    .object({
      fullName: yup
        .string()
        .required('Full name is required')
        .matches(/^(?!\s)/, 'Cannot start with a blank space'),
      phone: yup
        .string()
        .required('Phone is required')
        .matches(/^(?!\s)/, 'Cannot start with a blank space'),
      email: yup
        .string()
        .email('Invalid e-mail')
        .required('E-mail is required'),
      dateOfBirth: yup.date().nullable().typeError('Please provide full date'),
      salutation: yup
        .string()
        .required(
          'What should we call you? You can type ‘John’ or ‘Dr. White’, for example.',
        )
        .matches(/^(?!\s)/, 'Cannot start with a blank space'),
      address: yup
        .string()
        .required('Address is required')
        .matches(/^(?!\s)/, 'Cannot start with a blank space'),
      postCode: yup
        .string()
        .required('Postcode is required')
        .matches(/^(?!\s)/, 'Cannot start with a blank space'),
      countryId: yup.string(),
      companyName: yup.string(),
      cityName: yup
        .string()
        .required('City is required')
        .matches(/^(?!\s)/, 'Cannot start with a blank space'),
      password: yup
        .string()
        .matches(
          /^.*((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          'Invalid password',
        )
        .required('Password is required'),
      passwordConfirmation: yup
        .string()
        .required('Password confirmation is required')
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
      tellUsMore: yup.string(),
      generalTermsAcceptedOnline: yup
        .boolean()
        .oneOf([true], 'Acceptance of terms and conditions is required'),
    })
    .required();

const confirmBooking = async (data: NewBookingProps | undefined) => {
  await API.post(APIRoutes.bookings.postBooking, data);
};

const ExternalRegisterForm = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const { restoreUser } = useAuth();
  const { snack, setSnack } = useToast();

  const spaceId = searchParams.get('spaceId');
  const resourceId = searchParams.get('id');
  const from = searchParams.get('from');
  const to = searchParams.get('to');

  const handleSuccess = async () => {
    queryClient.invalidateQueries('bookings');
    queryClient.invalidateQueries('rooms');
    queryClient.invalidateQueries('profile');
    setSnack({
      ...snack,
      message: 'Booking tentatively confirmed',
      open: true,
      type: 'success',
    });
    restoreUser();
    navigate(routes.main.externalBookingsPayment);
    // window.location.href = routes.main.externalBookingsPayment;
  };

  const { mutate: bookRoom, isLoading: isBooking } = useMutation(
    async (payload: { fullName: string | undefined }) =>
      confirmBooking({
        resourceId: resourceId ? parseInt(resourceId, 10) : undefined,
        to: to || '',
        from: from || '',
        title: `External Booking: ${payload.fullName}`,
        discountCode: state?.discountCode,
      }),
    {
      mutationKey: 'confirm-external-booking',
      onSuccess: handleSuccess,
      retry: 0,
      onError: (error: any) =>
        setSnack({
          ...snack,
          message: `An error occurred: ${error.message}`,
          open: true,
          type: 'error',
        }),
    },
  );

  const { mutate: signUp, isLoading: isMutating } = useMutation(
    (payload: any) => API.post(APIRoutes.auth.signUp, payload),
    {
      mutationKey: 'sign-up',
      onSuccess: async ({ data }, payload) => {
        setAuthenticationToken(data?.token);
        setItem(KEYS.REFRESH_TOKEN, data?.refreshToken);
        bookRoom(payload);
      },
      onError: (err: { message: string }) => {
        setSnack({
          ...snack,
          message: `Unable to sign up ${
            err?.message.toLocaleLowerCase() || ''
          }`,
          color: 'white',
          open: true,
          type: 'error',
          icon: (
            <ErrorOutlineOutlinedIcon
              sx={{
                color: 'white',
                width: '20px',
                height: '20px',
              }}
            />
          ),
        });
      },
    },
  );

  const methods = useForm<FormData>({
    mode: 'onChange',
    resolver: yupResolver(wrapperSchema()),
    defaultValues: {
      countryId: '1220',
      generalTermsAcceptedOnline: false,
    },
  });
  const { handleSubmit } = methods;

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    const payload = {
      ...data,
      inductionSpaceId: spaceId ? parseInt(spaceId, 10) : undefined,
      external: true,
      dateOfBirth: moment(data.dateOfBirth).format('YYYY-MM-DD'),
    };
    signUp(payload);
  };

  return (
    <>
      <SectionHeader
        title="Join Work.Life"
        backLink={-1}
        elements={
          <>
            <span>If you already have an account</span>
            <Button
              variant="contained"
              color="secondary"
              href={routes.auth.signIn}
            >
              Sign In
            </Button>
          </>
        }
        path={[
          {
            label: 'Meeting Rooms',
            href: -2,
          },
          {
            label: searchParams.get('resourceName'),
            href: -1,
          },
          { label: 'Your Details' },
        ]}
      />
      <Section>
        <FormProvider {...methods}>
          <form id="step-1" onSubmit={handleSubmit(onSubmit)} noValidate>
            <DetailsForm isSignUp />
            <AddressForm isSignUp />
            <PasswordForm />
            <TellUsMoreForm />
            <Section
              sx={{
                padding: '0 40px 64px 40px',
                background: 'transparent',
              }}
            >
              <CheckBox
                label={
                  <>
                    Check this box to indicate you agree with our&nbsp;
                    <span onClick={(e) => e.stopPropagation()}>
                      <Link
                        href="https://work.life/memberterms/"
                        target="_blank"
                      >
                        terms and conditions
                      </Link>
                    </span>
                    .
                  </>
                }
                name="generalTermsAcceptedOnline"
              />
            </Section>
          </form>
        </FormProvider>
        <Section
          sx={{
            padding: '0 40px 64px 40px',
            background: 'transparent',
          }}
        >
          <LoadingButton
            isLoading={isMutating || isBooking}
            variant="contained"
            color="secondary"
            sx={{
              width: '168px',
              height: '44px',
              justifyContent: 'center',
            }}
            type="submit"
            form="step-1"
          >
            Continue
          </LoadingButton>
        </Section>
      </Section>
    </>
  );
};

export default ExternalRegisterForm;
