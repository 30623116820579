export const LIMITS = {
  LISTING: 6,
  DASHBOARD_SECTION: 10,
  VISITORS: 4,
  DELIVERIES: 4,
  ALERTS: 10,
  TICKETS: 10,
};

export const MENU_TYPES = {
  LOCATION: 'locationMenu',
  WIFI: 'wifi',
  CONFIRMATION: 'confirmation',
};

export const BOOKING_TABLE_START = '00:15';
export const BOOKING_TABLE_END = '24:00';
