import moment from 'moment';

export const getTimeArray = (
  from: AvailabilityTime,
  to: AvailabilityTime,
  values: { day?: string; month?: string },
) => {
  if (!values.day) return [];
  if (!values.month) return [];
  const day = moment(new Date(values.day)).date();
  const month = moment(new Date(values.month));

  const start = moment().startOf('day').set({
    hour: from.hour,
    minute: from.minute,
    date: day,
    month: month.month(),
    year: month.year(),
  });

  const end = start.clone().set({ hour: to.hour, minute: to.minute });

  const result = [];
  while (start.isBefore(end)) {
    result.push({
      label: start.format('HH:mm'),
      value: start.toString(),
    });
    start.add(15, 'minutes');
  }
  return result;
};

export const getDurationArray = ({ hour, minute }: AvailabilityTime) => {
  const start = moment().startOf('day').set({ minutes: 15 });
  const end = moment().startOf('day').set({ hour, minute });
  const result = [];
  while (start.isSameOrBefore(end)) {
    const hours = start.hours() ? `${start.hours()}hr` : '';
    const minutes = start.minutes()
      ? `${start.hours() < 1 ? `${start.minutes()} min` : start.minutes()}`
      : '';
    result.push({
      label: `${hours} ${minutes}`,
      value: start.format('HH:mm'),
    });
    start.add(start.hours() < 1 ? 15 : 30, 'minutes');
  }
  return result;
};

export const getDaysArray = (selected: string | undefined) => {
  if (!selected) return [];
  const selectedDate = new Date(selected);
  const daysInMonth = moment(selectedDate).daysInMonth();
  const days = Array.from({ length: daysInMonth }, (_, i) => ({
    label: (i + 1).toString(),
    value: moment(selectedDate)
      .set({ date: i + 1 })
      .toString(),
  }));
  return days;
};

export const CAPACITY = Array.from({ length: 20 }, (_, i) => ({
  label: (i + 1).toString(),
  value: (i + 1).toString(),
}));

export const MONTHS = Array.from({ length: 13 }, (_, i) => {
  const day = moment().startOf('month').add(i, 'month');
  return {
    label: day.format('MMMM YYYY'),
    value: day.toString(),
  };
});

export const SLIDER_PER_VIEW: { [id: string]: any } = {
  location: 3.5,
  month: 3.5,
  day: 12.5,
  year: 6.5,
  time: 6.25,
  duration: 6.25,
  capacity: 12.5,
  slot: 5.25,
};

export const isOptionDisabled = (category: string, option: string) => {
  const date = new Date(option);
  if (category === 'day') {
    return moment(date).isBefore(moment().startOf('day'));
  }
  if (category === 'time') {
    return moment(date).isBefore(moment());
  }
  return false;
};

export const timeTable = (startString: string, endString: string) => {
  const start = moment(startString, 'HH:mm') || '07:00';
  const end = moment(endString, 'HH:mm') || '17:30';

  // round starting minutes up to nearest 15 (12 --> 15, 17 --> 30)
  // note that 59 will round up to 60, and moment.js handles that correctly
  start.minutes(Math.ceil(start.minutes() / 15) * 15);

  const result = [];

  const current = moment(start);

  while (current <= end) {
    result.push(current.format('HH:mm'));
    current.add(15, 'minutes');
  }

  return result;
};
