/* eslint-disable import/extensions */
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { isEmpty, filter, every } from 'lodash-es';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { Box, Button, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import NavigationIcon from 'assets/icons/NavigationIcon';
import {
  IconBox,
  NavigationBox,
  NavigationButton,
} from 'components/Rooms/style';
import { DEFAULT_STATE } from 'constants/rooms';

import CircleWithPlus from 'assets/icons/PlusWithCircleIcon';
import ToastSuccessIcon from 'assets/icons/ToastSuccessIcon';
import 'swiper/css';
import routes from 'utils/routes';

enum SLOT_COLOR {
  AVAILABLE = '#F7DFC0',
  NOT_AVAILABLE = '#F1F1F1',
  // BOOKED_BY_USER = '#68CA3D4D',
  SELECTED = '#FABE79',
  SELECTED_TIME = '#4537ce',
}

export type State = {
  space?: number;
  name: string;
  id: number;
  from: string;
  to: string;
};

type SliderProps = {
  showLabels: boolean;
  room: Room;
  selected: State;
  setSelected: React.Dispatch<React.SetStateAction<State>>;
  isResultsView: boolean;
  isExternal?: boolean;
  timeIndex: number;
  setTimeIndex: (index: number) => void;
};

const Slider = ({
  showLabels,
  selected,
  setSelected,
  room,
  isResultsView,
  isExternal,
  timeIndex,
  setTimeIndex,
}: SliderProps) => {
  const [hoverDate, setHoverDate] = useState('');
  const [swiperRef, setSwiperRef] = useState<SwiperCore>();
  const theme = useTheme();
  const navigate = useNavigate();

  const handleLeftClick = useCallback(() => {
    if (!swiperRef) return;
    swiperRef.slidePrev();
  }, [swiperRef]);

  const handleRightClick = useCallback(() => {
    if (!swiperRef) return;
    swiperRef.slideNext();
  }, [swiperRef]);

  const [isBeginning, setIsBeginning] = useState<boolean>(true);
  const [isEnd, setIsEnd] = useState<boolean>(false);

  const isOptionSelected = useCallback(
    (option: RoomAvailability) => {
      const from = moment(selected?.from);
      const to = moment(selected?.to);
      const optionTime = moment(option.date);
      return (
        (isResultsView || selected?.id === room?.id) &&
        optionTime.isBetween(from, to, undefined, '[)')
      );
    },
    [selected],
  );

  const isValidRange = useCallback(
    (from: string, to: string) => {
      const start = room?.availability?.findIndex((item) => item.date === from);
      const end = room?.availability?.findIndex((item) => item.date === to);
      if (start >= end) return false;

      const timeRange = room?.availability?.slice(start, end + 1);
      return timeRange.every((item) => item.available && !item.booked);
    },
    [room],
  );

  const bookingEnabled = useMemo(() => {
    if (!selected.from || !selected.to) return false;
    const from = moment(selected.from);
    const to = moment(selected.to);
    const slots = filter(room?.availability, (slot) => {
      const slotStart = moment(slot.date);
      return slotStart.isSameOrAfter(from) && slotStart.isBefore(to);
    });
    const allFree = every(slots, (slot) => slot.available && !slot.booked);
    // console.log(`SLOTS (${room.name}) freee: ${allFree}:`, slots);
    // console.log('SELECTED: ', selected.id);
    return allFree && (!selected.id || selected.id === room.id);
  }, [selected, room]);

  const setHoveredRange = (e: any, option: RoomAvailability) => {
    if (
      !(option.available && !option.booked) ||
      isEmpty(selected?.from) ||
      !moment(option.date).isAfter(selected?.from)
    )
      return;
    const value =
      e.type === 'mouseleave' ||
      !isValidRange(selected?.from, option.date) ||
      !(option.available && !option.booked)
        ? ''
        : option.date;

    setHoverDate(value);
  };

  // console.log('SELECTED: ', selected);

  const handleClick = useCallback(
    (option: RoomAvailability, nextOption: RoomAvailability) => {
      if (option.available && !option.booked) {
        if (
          room?.id === selected?.id &&
          selected?.from &&
          selected?.to &&
          isValidRange(selected?.from, option.date)
        ) {
          setSelected({
            ...selected,
            to: nextOption.date,
          });
        } else {
          setHoverDate('');
          if (selected?.id === room?.id && selected.from === option.date) {
            // unselect choice by clicking first time slot again
            setSelected(DEFAULT_STATE);
          } else {
            setSelected({
              space: selected?.space,
              name: room?.name,
              from: option.date,
              to: nextOption.date,
              id: room?.id,
            });
          }
        }
      }
    },
    [room, selected],
  );

  useEffect(() => {
    if (swiperRef && !swiperRef.destroyed) {
      swiperRef.slideTo(timeIndex);
    }
  }, [swiperRef, timeIndex]);

  const bookNow = useCallback(() => {
    if (isExternal) {
      navigate(
        routes.main.externalBookingsReview(
          room.id,
          selected.space,
          selected?.from,
          selected?.to,
        ),
      );
    } else {
      navigate(routes.bookings(room.id, selected?.from, selected?.to), {
        state: {
          capacity: 1,
          returnToDefaultView: true,
        },
      });
    }
  }, [isExternal, room, selected]);

  return (
    <Box sx={{ minWidth: 0, display: 'flex' }}>
      <NavigationBox
        sx={{
          position: 'static',
          paddingTop: '32px',
          minWidth: '54px',
        }}
      >
        {!isBeginning && (!isResultsView || isExternal) && (
          <NavigationButton
            onClick={handleLeftClick}
            sx={{ marginLeft: '11px' }}
          >
            <NavigationIcon
              sx={{
                padding: '7px',
              }}
            />
          </NavigationButton>
        )}
      </NavigationBox>
      <Box display="flex" position="relative" sx={{ minWidth: 0 }}>
        <Swiper
          onSwiper={setSwiperRef}
          mousewheel
          spaceBetween={8}
          slidesPerView="auto"
          slidesPerGroupAuto
          normalizeSlideIndex={false}
          shortSwipes={false}
          threshold={10}
          onInit={(e) => {
            setIsBeginning(e.isBeginning);
            setIsEnd(e.isEnd);
          }}
          onActiveIndexChange={(e) => {
            setIsBeginning(e.isBeginning);
            setIsEnd(e.isEnd);
            setTimeIndex(e.activeIndex);
          }}
          style={{
            paddingLeft: '25px',
            paddingRight: '25px',
            ...(showLabels && {
              paddingTop: '50px',
              marginTop: '-50px',
            }),
          }}
        >
          {room?.availability?.map((option, index, options) => {
            const time = moment(option?.date).format('HH:mm');
            const nextOption = options[index + 1] || {
              date: moment(option.date).add(15, 'minutes').toISOString(),
              available: true,
              booked: false,
            };

            const isAvailable = option.available && !option.booked;
            const isSelected = isOptionSelected(option);

            const isHovered =
              isAvailable &&
              room.id === selected?.id &&
              moment(option.date).isAfter(selected?.from) &&
              moment(option.date).isSameOrBefore(hoverDate);

            const showSelectedTime =
              time >= moment(selected.from).format('HH:mm') &&
              time <= moment(selected.to).format('HH:mm');

            let slotColor = SLOT_COLOR.AVAILABLE;
            if (!isAvailable) {
              slotColor = SLOT_COLOR.NOT_AVAILABLE;
            } else if (
              (isSelected || isHovered) &&
              (!isExternal || room.id === selected.id || !selected.id)
            ) {
              slotColor = SLOT_COLOR.SELECTED;
            }

            return (
              <SwiperSlide
                key={`${room.id}-${option.date}`}
                style={{
                  width: 'auto',
                }}
              >
                {showLabels && isExternal && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '-38px',
                      left: '-22px',
                    }}
                  >
                    <Typography
                      variant="h2"
                      fontSize="14px"
                      fontWeight={
                        time.includes(':00') || showSelectedTime ? 700 : 500
                      }
                      color={showSelectedTime ? SLOT_COLOR.SELECTED_TIME : ''}
                      sx={{
                        lineHeight: '16px',
                      }}
                    >
                      {time}
                    </Typography>
                  </Box>
                )}
                <Box
                  onClick={() =>
                    isResultsView && !isExternal
                      ? {}
                      : handleClick(option, nextOption)
                  }
                  onMouseEnter={(e) => setHoveredRange(e, option)}
                  onMouseLeave={(e) => setHoveredRange(e, option)}
                  sx={{
                    width: '36px',
                    height: '96px',
                    backgroundColor: slotColor,
                    borderRadius: '4px',
                    position: 'relative',
                    transition: 'background 0.3s',
                    cursor: isAvailable ? 'pointer' : 'default',
                    '&:hover': {
                      backgroundColor: isAvailable
                        ? SLOT_COLOR.SELECTED
                        : slotColor,
                      transition: 'background 0.3s',

                      ...(isAvailable && {
                        '& span': { opacity: 1, transition: 'opacity 0.3s' },
                      }),
                    },

                    '& span': {
                      opacity: isHovered ? 1 : 0,
                      transition: 'opacity 0.3s',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',

                      '& svg': { color: '#f9f9f9' },
                    },
                  }}
                >
                  {(isAvailable || isHovered) &&
                    (!isResultsView || isExternal) && (
                      <span>
                        <CircleWithPlus />
                      </span>
                    )}
                  {isSelected &&
                    isAvailable &&
                    (!isExternal ||
                      selected.id === room.id ||
                      !selected.id) && (
                      <IconBox>
                        <ToastSuccessIcon fill="#F7DFC0" />
                      </IconBox>
                    )}
                </Box>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>
      {isExternal && (
        <NavigationBox
          sx={{
            paddingTop: '32px',
            position: 'static',
            minWidth: '43px',
            textAlign: 'right',
          }}
        >
          {!isEnd && (
            <NavigationButton
              onClick={handleRightClick}
              sx={{ marginLeft: '11px' }}
            >
              <NavigationIcon
                sx={{
                  padding: '7px',
                  transform: 'rotate(180deg)',
                }}
              />
            </NavigationButton>
          )}
        </NavigationBox>
      )}
      {isResultsView || isExternal ? (
        <Box display="flex" alignItems="center" marginLeft="8px">
          <Button
            variant="contained"
            disabled={!bookingEnabled}
            onClick={() => bookNow()}
            sx={{
              backgroundColor: theme.palette.primary.main,
              justifyContent: 'center',
              minWidth: '115px',
              width: '115px',
              height: '44px',
            }}
          >
            Book now
          </Button>
        </Box>
      ) : (
        <NavigationBox
          sx={{
            paddingTop: '32px',
            position: 'static',
            width: '100%',
            maxWidth: '43px',
            textAlign: 'right',
          }}
        >
          {!isEnd && (
            <NavigationButton
              onClick={handleRightClick}
              sx={{ marginLeft: '11px' }}
            >
              <NavigationIcon
                sx={{
                  padding: '7px',
                  transform: 'rotate(180deg)',
                }}
              />
            </NavigationButton>
          )}
        </NavigationBox>
      )}
    </Box>
  );
};

Slider.defaultProps = { isExternal: false };

export default Slider;
