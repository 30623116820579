import qs from 'query-string';

export default {
  auth: {
    signIn: '/sign-in',
    signUp: {
      index: (params?: any) =>
        params ? `/sign-up?${qs.stringify(params)}` : '/sign-up',
      plan: (params?: any) =>
        params ? `/sign-up/plan?${qs.stringify(params)}` : '/sign-up/plan',
      summary: '/sign-up/summary',
    },
    resetPassword: '/reset-password',
    doResetPassword: (token?: string | undefined) =>
      token ? `/reset-password/${token}` : '/reset-password/:token',
    activation: '/activation',
  },
  platform: {
    parserFeed: '/platform/parser-feed',
  },
  tourBooking: '/book-a-tour',
  main: {
    dashboard: '/dashboard',
    events: '/events',
    eventsBooked: '/events/booked',
    promotions: '/promotions',
    bookings: '/bookings',
    meetingsRoomsGallery: (params?: any) =>
      params
        ? `/bookings/gallery?${qs.stringify(params)}`
        : `/bookings/gallery`,
    meetingsRoomsCalendar: (params?: any) =>
      params
        ? `/bookings/calendar?${qs.stringify(params)}`
        : `/bookings/calendar`,
    externalBookings: '/external-bookings',
    externalBookingsGallery: (params?: any) =>
      params
        ? `/external-bookings/gallery?${qs.stringify(params)}`
        : '/external-bookings/gallery',
    externalBookingsCalendar: (params?: any) =>
      params
        ? `/external-bookings/calendar?${qs.stringify(params)}`
        : '/external-bookings/calendar',
    externalBookingsReview: (
      id?: number | string | undefined,
      space?: number,
      from?: string,
      to?: string,
    ) =>
      id
        ? `/external-bookings/review/${id}?from=${from}&to=${to}&spaceId=${space}`
        : '/external-bookings/review/:id',
    externalBookingsSignup: (params?: any) =>
      params
        ? `/external-bookings/register?${qs.stringify(params)}`
        : '/external-bookings/register',
    externalBookingsPayment: '/external-bookings/payment-details',
    desks: {
      form: '/desks',
      availability: '/desks/availability',
      table: '/desks/availability/table',
      charts: '/desks/availability/charts',
    },
    help: {
      raiseTicket: '/help/tickets/raise',
      ask: '/help/tickets/ask',
      faq: '/help/faq',
      ticket: '/help/ticket',
    },
    account: {
      dashboard: '/account/dashboard',
      profile: '/account/profile',
      invoices: '/account/invoices',
      plans: '/account/plans',
      metrics: '/account/metrics',
      team: '/account/team',
      creditCard: '/account/credit-card',
      directDebit: '/account/direct-debit',
    },
    visitors: '/visitors',
    deliveries: '/deliveries',
    store: '/store',
    basket: '/basket',
    alerts: '/alerts',
  },
  event: (id = null) => (id ? `/events/${id}` : '/events/:id'),
  upcomingBooking: (id?: number | string | undefined) =>
    id ? `/upcoming-booking/${id}` : '/upcoming-booking/:id',
  bookings: (id?: number | string | undefined, from?: string, to?: string) =>
    id ? `/bookings/${id}?from=${from}&to=${to}` : '/bookings/:id',
  promotion: (id = null) => (id ? `/promotions/${id}` : '/promotions/:id'),
};

export const breadcrumbGeneralMap: { [key: string]: string } = {
  '/events': 'Events',
  '/promotions': 'Perks',
  '/bookings/gallery': 'Meeting rooms',
  '/bookings/calendar': 'Meeting rooms',
  '/desks': 'Desk availability',
  '/help': 'Help/FAQs',
};
