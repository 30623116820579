import moment from 'moment';
import { Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { useQuery } from 'react-query';
import Slider from 'components/Slider';
import Section from 'components/Section';
import CalendarItem from 'components/Bookings/CalendarItem';
import AdBanner from 'components/AdBanner';
import MeetingRoomModal from 'components/Rooms/MeetingRoomModal';
import LoadingSpinner from 'components/Common/LoadingSpinner';

import { API, APIRoutes } from 'utils/api';
import routes from 'utils/routes';

import { useAppContext } from 'context/AppContext';

import { ACCOUNT_TYPES } from 'constants/account';
import { useAuth } from 'context/AuthContext';
import { BANNER } from 'constants/banner';

const getSlackInvite = async () => {
  const {
    data: { data },
  } = await API.get(APIRoutes.user.slack);
  return data;
};

const BookingsSection = () => {
  const { openModal } = useAppContext();
  const { userType } = useAuth();

  const navigate = useNavigate();

  const getBookings = async () => {
    const {
      data: { data },
    } = await API.get(APIRoutes.bookings.my);
    return data;
  };

  const { isLoading, isSuccess, data } = useQuery<BookingProps[]>(
    'bookings',
    () => getBookings(),
  );

  const {
    isLoading: isLoadingSlackInvite,
    isSuccess: isSuccessSlackInvite,
    data: slackInvite,
  } = useQuery('slack', () => getSlackInvite(), {
    enabled: [
      ACCOUNT_TYPES.RESIDENT_PREACTIVE,
      ACCOUNT_TYPES.RESIDENT_ACTIVE,
      ACCOUNT_TYPES.RES_TEAM,
    ].includes(userType),
  });

  return (
    <Section>
      {isLoading && isLoadingSlackInvite && (
        <LoadingSpinner minHeight="334px" />
      )}
      {isSuccess && isSuccessSlackInvite && (
        <>
          <Slider
            id="upcoming_bookings"
            title="Upcoming room bookings"
            type="bookings"
            emptyComponent
            items={data.map((el, index, arr) => {
              const prevElementFromDate = moment(arr[index - 1]?.checkIn);
              const currentElFromDate = moment(el?.checkOut);

              const isSameMonth = moment(prevElementFromDate).isSame(
                currentElFromDate,
                'month',
              );

              return (
                <>
                  {data.length > 0 && (
                    <Typography mb={2} fontWeight="bold" minHeight="24px">
                      {!isSameMonth ? currentElFromDate.format('MMMM') : ''}
                    </Typography>
                  )}
                  <CalendarItem key={`upcoming_bookings-${el._id}`} {...el} />
                </>
              );
            })}
          />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap="20px"
            mt="26px"
          >
            <Typography fontSize="20" fontWeight="bold">
              {isEmpty(data)
                ? `You don't have any upcoming bookings`
                : 'Need to book another?'}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                openModal(<MeetingRoomModal />);
              }}
            >
              Book a room
            </Button>
          </Box>
          {[ACCOUNT_TYPES.FLEX, ACCOUNT_TYPES.UNLIMITED].includes(userType) ? (
            <AdBanner
              image={BANNER.DESK_AVAILABILITY.image}
              type={BANNER.DESK_AVAILABILITY.type}
              title={BANNER.DESK_AVAILABILITY.title}
              subtitle={BANNER.DESK_AVAILABILITY.text}
              label={BANNER.DESK_AVAILABILITY.label}
              onClick={() => navigate(routes.main.desks.form)}
            />
          ) : (
            <AdBanner
              image={BANNER.SLACK.image}
              type={BANNER.SLACK.type}
              title={BANNER.SLACK.title}
              subtitle={BANNER.SLACK.text}
              label={BANNER.SLACK.label}
              onClick={() => window.open(slackInvite.url, '_blank')}
            />
          )}
        </>
      )}
    </Section>
  );
};

export default BookingsSection;
