export const OPTIONS = {
  scales: {
    x: {
      grid: {
        display: false,
        borderColor: '#1D1954',
      },
      ticks: {
        color: '#1D1954',
        font: {
          family: '"Antebas", "Roboto", sans-serif',
          size: 13,
          weight: '700',
        },
      },
    },
    y: {
      grid: {
        color: 'rgba(217, 223, 239, 0.3)',
        drawBorder: false,
        tickColor: 'white',
      },
      ticks: {
        display: true,
        stepSize: 1,
        color: '#1D1954',
        font: {
          family: '"Antebas", "Roboto", sans-serif',
          size: 13,
          weight: '700',
        },
      },
    },
  },
  maintainAspectRatio: false,

  plugins: {
    legend: {
      display: false,
      position: 'top' as const,
      align: 'end' as const,
      labels: {
        boxWidth: 20,
        boxHeight: 20,
        useBorderRadius: true,
        borderRadius: 4,
        font: {
          size: 16,
        },
      },
    },
    tooltip: {
      backgroundColor: '#fff',
      borderColor: '#FABE79',
      borderWidth: 1,
      padding: 20,
      titleColor: '#1D1954',
      titleFont: { weight: '400' },
      bodyColor: '#1D1954',
      bodyFont: { weight: 'bold' },
      displayColors: false,
    },
  },
};

export const METRICS_DATA = {
  status: 'success',
  data: [
    {
      monthDate: '2021-11-01',
      teamInvoicedAmount: 0,
      invoicedAmount: 5,
      checkingMinutes: 86,
      teamCheckingMinutes: 0,
      bookingMinutes: 135,
      teamBookingMinutes: 0,
    },
    {
      monthDate: '2021-12-01',
      teamInvoicedAmount: 0,
      invoicedAmount: 4,
      checkingMinutes: 120,
      teamCheckingMinutes: 0,
      bookingMinutes: 180,
      teamBookingMinutes: 0,
    },
    {
      monthDate: '2022-01-01',
      teamInvoicedAmount: 0,
      invoicedAmount: 2,
      checkingMinutes: 120,
      teamCheckingMinutes: 0,
      bookingMinutes: 180,
      teamBookingMinutes: 0,
    },
    {
      monthDate: '2022-02-01',
      teamInvoicedAmount: 0,
      invoicedAmount: 1,
      checkingMinutes: 120,
      teamCheckingMinutes: 0,
      bookingMinutes: 180,
      teamBookingMinutes: 0,
    },
    {
      monthDate: '2022-03-01',
      teamInvoicedAmount: 0,
      invoicedAmount: 0,
      checkingMinutes: 120,
      teamCheckingMinutes: 0,
      bookingMinutes: 180,
      teamBookingMinutes: 0,
    },
    {
      monthDate: '2022-04-01',
      teamInvoicedAmount: 0,
      invoicedAmount: 4,
      checkingMinutes: 120,
      teamCheckingMinutes: 0,
      bookingMinutes: 180,
      teamBookingMinutes: 0,
    },
    {
      monthDate: '2022-05-01',
      teamInvoicedAmount: 0,
      invoicedAmount: 0,
      checkingMinutes: 120,
      teamCheckingMinutes: 0,
      bookingMinutes: 180,
      teamBookingMinutes: 0,
    },
    {
      monthDate: '2022-06-01',
      teamInvoicedAmount: 0,
      invoicedAmount: 3,
      checkingMinutes: 120,
      teamCheckingMinutes: 0,
      bookingMinutes: 180,
      teamBookingMinutes: 0,
    },
    {
      monthDate: '2022-07-01',
      teamInvoicedAmount: 0,
      invoicedAmount: 5,
      checkingMinutes: 120,
      teamCheckingMinutes: 0,
      bookingMinutes: 180,
      teamBookingMinutes: 0,
    },
    {
      monthDate: '2022-08-01',
      teamInvoicedAmount: 0,
      invoicedAmount: 1,
      checkingMinutes: 120,
      teamCheckingMinutes: 0,
      bookingMinutes: 180,
      teamBookingMinutes: 0,
    },
    {
      monthDate: '2022-09-01',
      teamInvoicedAmount: 0,
      invoicedAmount: 2,
      checkingMinutes: 120,
      teamCheckingMinutes: 0,
      bookingMinutes: 180,
      teamBookingMinutes: 0,
    },
    {
      monthDate: '2022-10-01',
      teamInvoicedAmount: 0,
      invoicedAmount: 4,
      checkingMinutes: 120,
      teamCheckingMinutes: 0,
      bookingMinutes: 135,
      teamBookingMinutes: 0,
    },
    {
      monthDate: '2022-11-01',
      teamInvoicedAmount: 0,
      invoicedAmount: 3,
      checkingMinutes: 120,
      teamCheckingMinutes: 0,
      bookingMinutes: 135,
      teamBookingMinutes: 0,
    },
  ],
};
