/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/ban-types */
import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  ReactNode,
} from 'react';
import Modal from 'components/Common/Modal';

type ProductQuantity = {
  id: number;
  quantity: number;
};
interface IState {
  [type: string]: boolean;
}
type AppContextType = {
  anchorEl: IState;
  handleClose: any;
  setAnchorEl: React.Dispatch<React.SetStateAction<IState>>;
  handleClick: any;
  isOpen: boolean;
  openModal: (content: any) => void;
  closeModal: () => void;
  content: ReactNode;
  products: ProductQuantity[];
  updateQuantity: (id: number, changeValue: number) => void;
  getQuantity: (id: number) => number;
  resetQuantity: () => void;
  timeIndex: number;
};

const AppContextDefaultState: AppContextType = {
  anchorEl: {
    wifi: false,
    locationMenu: false,
    confirmation: false,
  },
  handleClose: () => {},
  setAnchorEl: () => {},
  handleClick: () => {},
  openModal: () => {},
  closeModal: () => {},
  isOpen: false,
  content: null,
  products: [],
  updateQuantity: () => {},
  getQuantity: () => 0,
  resetQuantity: () => {},
  timeIndex: 0,
};

type Props = {
  children: ReactNode;
};

const AppContext = createContext(AppContextDefaultState);

export const AppContextProvider = ({ children }: Props) => {
  const [anchorEl, setAnchorEl] = useState<IState>({
    wifi: false,
    locationMenu: false,
    confirmation: false,
  });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [content, setContent] = useState(null);
  const [products, setProducts] = useState<ProductQuantity[]>([]);

  const openModal = (modalContent: any) => {
    setContent(modalContent);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setContent(null);
  };

  const updateQuantity = (id: number, changeValue: number) => {
    const updatedProducts = [...products];
    let index = updatedProducts.findIndex((product) => product.id === id);
    if (index === -1) {
      index =
        updatedProducts.push({
          id,
          quantity: 1,
        }) - 1;
    }
    updatedProducts[index].quantity += changeValue;
    setProducts(updatedProducts);
  };

  const getQuantity = (id: number) => {
    const item = products.find((product: ProductQuantity) => product.id === id);
    return item ? item.quantity : 1;
  };

  const resetQuantity = () => {
    setProducts([]);
  };

  const globalContextValue = useMemo(
    () => ({
      ...AppContextDefaultState,
      setAnchorEl,
      anchorEl,
      openModal,
      closeModal,
      isOpen,
      content,
      products,
      updateQuantity,
      getQuantity,
      resetQuantity,
    }),
    [anchorEl, isOpen, products],
  );

  return (
    <AppContext.Provider value={globalContextValue}>
      {isOpen && <Modal content={content} closeModal={closeModal} />}
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
