import { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import ArrowButton from 'assets/icons/ArrowButtonSecond';
import { Title } from 'components/Title';
import routes from 'utils/routes';
import { useAuth } from 'context/AuthContext';
import LocationDropdown from 'components/Common/LocationDropdown';

const HeaderSection = ({
  date,
  extendedGrid,
  loc,
  changeLoc,
  element,
}: {
  date: string;
  extendedGrid: boolean;
  loc: number | undefined;
  changeLoc: (value: number) => void;
  element: ReactNode;
}) => {
  const navigate = useNavigate();
  const { currentSpaceId, spaces } = useAuth();

  return (
    <>
      {extendedGrid ? (
        <Box
          sx={{ '& svg': { fontSize: '3rem' } }}
          display="flex"
          alignItems="center"
          mb="16px"
          gap="8px"
        >
          <ArrowButton
            sx={{ cursor: 'pointer' }}
            onClick={() =>
              navigate(
                routes.main.meetingsRoomsGallery({
                  spaceId: currentSpaceId === 0 ? loc : currentSpaceId,
                }),
              )
            }
          />
          <Title variant="h3">Upcoming & available</Title>
        </Box>
      ) : (
        element
      )}
      <LocationDropdown
        items={spaces.filter((space) => space.id)}
        currentId={loc as number}
        onSelect={changeLoc}
      />
      {extendedGrid && (
        <Typography fontWeight="700" mt="24px">
          {moment(date).format('dddd D MMMM YYYY')}
        </Typography>
      )}
    </>
  );
};

export default HeaderSection;
