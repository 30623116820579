import React, { useMemo } from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash-es';
import { useLocation, useSearchParams } from 'react-router-dom';

import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import SectionHeader from 'components/SectionHeader';
import MeetingRoomModal from 'components/Rooms/MeetingRoomModal';

import { useAppContext } from 'context/AppContext';
import EditIcon from 'assets/icons/EditIcon';

const breadcrumbsConfig = () => [{ label: 'Meeting Rooms' }];

const HeaderSection = ({
  setLocalization,
  isSearch,
}: {
  setLocalization: (loc: number | undefined) => void;
  isSearch: boolean;
}) => {
  const theme = useTheme();
  const { openModal } = useAppContext();
  // const { currentSpaceId, setUserSpaceId } = useAuth();

  const location = useLocation();
  const [searchParams] = useSearchParams();

  const bookingData = useMemo(() => {
    if (searchParams.get('capacity') === null) return {};

    const start = moment(searchParams.get('from'));
    const end = moment(searchParams.get('to'));
    const timeDifference = end.diff(start);
    const duration = moment.utc(timeDifference).format('HH:mm');

    return {
      month: moment(searchParams.get('from')).startOf('month').toString(),
      day: moment(searchParams.get('to')).startOf('day').toString(),
      time: start.toString(),
      duration,
      capacity: searchParams.get('capacity'),
      location: searchParams.get('spaceId'),
      bookingId: null,
    };
  }, [location]);

  return (
    <SectionHeader
      title={!isSearch ? 'Meeting rooms' : 'Your search results'}
      path={breadcrumbsConfig()}
      hideBackButton
      elements={
        <Button
          onClick={() =>
            openModal(
              <MeetingRoomModal
                setLocalization={setLocalization}
                initialValues={bookingData as any}
              />,
            )
          }
          variant="contained"
          sx={{
            justifyContent: 'center',
            backgroundColor: isEmpty(bookingData)
              ? theme.palette.primary.main
              : theme.palette.secondary.main,
            width: '200px',
            height: '48px',
            color: isEmpty(bookingData) ? '#fff' : theme.palette.text.primary,

            '&:hover': {
              backgroundColor: isEmpty(bookingData)
                ? theme.palette.primary.main
                : theme.palette.secondary.main,
            },
          }}
          endIcon={
            isEmpty(bookingData) ? null : (
              <EditIcon
                sx={{
                  marginRight: 1,
                  width: '24px',
                  height: '24px',
                }}
              />
            )
          }
        >
          {isEmpty(bookingData) ? 'Book a room' : 'Edit search'}
        </Button>
      }
    />
  );
};

export default HeaderSection;
