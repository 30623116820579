import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useAppContext } from 'context/AppContext';
import CloseIcon from 'assets/icons/CloseIcon';
import SearchForm from 'components/Rooms/SearchForm';

const MeetingRoomModal = ({
  initialValues,
  isBooked = false,
  setLocalization,
}: {
  initialValues?: {
    location?: string;
    month?: string;
    day?: string;
    time?: string;
    duration?: string;
    people?: string;
    bookingId?: string;
  };
  isBooked?: boolean;
  setLocalization?: (value: number) => void | undefined;
}) => {
  const theme = useTheme();
  const { closeModal } = useAppContext();

  return (
    <Card
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '760px',
        height: '600px',
        overflowY: 'auto',
      }}
    >
      <Box position="sticky" top="0px" zIndex="101" bgcolor="background.card">
        <CardHeader
          action={
            <IconButton
              aria-label="close"
              onClick={() => closeModal()}
              sx={{
                position: 'absolute',
                top: '16px',
                right: '14px',
                width: '20px',
                height: '20px',
                background: theme.palette.secondary.main,
                '&:hover': {
                  background: theme.palette.secondary.main,
                },
              }}
            >
              <CloseIcon
                sx={{
                  width: '14px',
                  height: '14px',
                  color: theme.palette.blue[800],
                }}
              />
            </IconButton>
          }
          title={
            isBooked ? 'Edit your meeting room' : 'Search for a meeting room'
          }
          titleTypographyProps={{
            variant: 'h2',
          }}
          sx={{
            padding: '32px',
          }}
        />
        {isBooked && (
          <Typography sx={{ padding: '0 32px 32px', marginTop: '-27px' }}>
            {initialValues?.location}
          </Typography>
        )}
        <Divider />
      </Box>
      <CardContent
        sx={{
          padding: '0px 32px 24px 32px',
        }}
      >
        <SearchForm
          initialValues={initialValues}
          isBooked={isBooked}
          setLocalization={setLocalization}
        />
      </CardContent>
    </Card>
  );
};

MeetingRoomModal.defaultProps = {
  initialValues: {},
  isBooked: false,
  setLocalization: null,
};

export default MeetingRoomModal;
