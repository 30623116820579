import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { get } from 'lodash-es';
import { useNavigate } from 'react-router-dom';
import ToastSuccessIcon from 'assets/icons/ToastSuccessIcon';
import routes from 'utils/routes';
import UploadFormsButton from 'components/Home/OnboardingModal/UploadFormsButton';
import DownloadInvoiceButton from 'components/Home/OnboardingModal/DownloadInvoiceButton';

type TasksListProps = {
  tasks: Tasks;
  closeModal: () => void;
};

const TasksList = ({ tasks, closeModal }: TasksListProps) => {
  const navigate = useNavigate();

  const steps = useMemo(
    () => [
      {
        title: 'Pay 1st security deposit invoice',
        accessorKey: 'isSecurityDepositPaid',
        buttonComponent: (
          <DownloadInvoiceButton
            invoice={tasks.isSecurityDepositPaid?.invoice}
          />
        ),
      },
      {
        title: 'Submit logo',
        accessorKey: 'isLogoSubmitted',
        button: {
          label: 'Submit',
          onClick: () => {
            closeModal();
            navigate(routes.main.account.team);
          },
        },
      },
      {
        title: 'Submit LOA/BR',
        accessorKey: 'isLOAUploaded',
        buttonComponent: <UploadFormsButton />,
      },
      {
        title: 'Set Up DD',
        accessorKey: 'isDirectDebitActive',
        button: {
          label: 'Set up',
          onClick: () => {
            closeModal();
            navigate(`${routes.main.account.profile}#payment`);
          },
        },
      },
      // {
      //   title: 'Book your induction',
      //   accessorKey: 'isInductionBooked',
      //   button: {
      //     label: 'Book',
      //     onClick: () => console.log('Book induction'),
      //   },
      // },
      {
        title: 'Invite your team',
        accessorKey: 'isTeamMembersInvited',
        button: {
          label: 'Invite',
          onClick: () => {
            closeModal();
            navigate(routes.main.account.team, {
              state: { shouldOpenModal: true },
            });
          },
        },
      },
    ],
    [closeModal, navigate],
  );

  return (
    <Stack
      divider={<Divider />}
      bgcolor="background.card"
      sx={{
        padding: '6px 24px',
        borderRadius: '8px',
      }}
    >
      {steps.map(({ accessorKey, title, button, buttonComponent }) => {
        const task = get(tasks, accessorKey);

        return (
          <Stack
            key={accessorKey}
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '8px 0px',
            }}
          >
            <Typography>{title}</Typography>
            <Box
              sx={{
                width: '105px',
                height: '46px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {task.completed ? (
                <ToastSuccessIcon />
              ) : (
                buttonComponent || (
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    sx={{
                      justifyContent: 'center',
                    }}
                    onClick={() => button.onClick()}
                  >
                    {button.label}
                  </Button>
                )
              )}
            </Box>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default TasksList;
