import InstagramIcon from 'assets/icons/InstagramIcon';
import TwitterIcon from 'assets/icons/TwitterIcon';
import FacebookIcon from 'assets/icons/FacebookIcon';
import LinkedinIcon from 'assets/icons/LinkedinIcon';
import routes from 'utils/routes';

export const NAVIGATION_LIST_FOOTER = {
  firstSection: [
    {
      label: 'Home',
      href: routes.main.dashboard,
    },
    {
      label: 'Events',
      href: routes.main.events,
    },
    {
      label: 'Perks',
      href: routes.main.promotions,
    },
    {
      label: 'Meeting rooms',
      href: routes.main.meetingsRoomsGallery(),
    },
    // {
    //   label: 'Desk availability',
    //   href: routes.main.desks.form,
    // },
    {
      label: 'Help',
      href: routes.main.help.ticket,
    },
    {
      label: 'FAQs',
      href: routes.main.help.faq,
    },
  ],
  secondSection: [
    {
      label: 'Visitors',
      href: routes.main.visitors,
    },
    {
      label: 'Deliveries',
      href: routes.main.deliveries,
    },
    {
      label: 'Store',
      href: routes.main.store,
    },
    {
      label: 'Basket',
      href: routes.main.basket,
    },
    {
      label: 'Alerts',
      href: routes.main.alerts,
    },
    {
      label: 'Account',
      href: routes.main.account.dashboard,
    },
  ],
  thirdSection: [
    {
      label: 'Terms & Conditions',
      href: '#',
    },
    {
      label: 'Cookie policy',
      href: '#',
    },
    {
      label: 'Sustainability Data',
      href: '/data-sharing.pdf',
    },
    {
      label: 'Logout',
      href: '#',
    },
  ],
};

export const SOCIAL_MEDIA_LIST = [
  {
    label: 'Instagram',
    icon: <InstagramIcon />,
    href: 'https://www.instagram.com/workdotlife/',
  },
  {
    label: 'Facebook',
    icon: <FacebookIcon />,
    href: 'https://www.facebook.com/workdotlife/',
  },
  {
    label: 'Twitter',
    icon: <TwitterIcon />,
    href: 'https://twitter.com/workdotlife',
  },
  {
    label: 'LinkedIn',
    icon: <LinkedinIcon />,
    href: 'https://www.linkedin.com/company/workdotlife/',
  },
];
