import React, { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash-es';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import COUNTRIES from 'assets/countries.json';
import SectionHeader from 'components/SectionHeader';
import CreditCardDetailsForm from 'components/Profile/CreditCardDetailsForm';
import routes from 'utils/routes';
import { API, APIRoutes } from 'utils/api';
import Loader from 'components/Loader';
import { useToast } from 'context/ToastContext';
import { useAuth } from 'context/AuthContext';

const schema = yup.object({
  firstName: yup.string().required('First name is required').trim(),
  lastName: yup.string().required('Last name is required').trim(),
  number: yup.string().required('Card number is required').trim(),
  verificationValue: yup.number().required('Security code is required'),
  month: yup.number().required('Month is required'),
  year: yup.number().required('Year is required'),
  country: yup.string().nullable(),
  address1: yup.string().nullable(),
  city: yup.string().nullable(),
  state: yup.string().nullable(),
  zip: yup.string().nullable(),
});

type FormData = yup.InferType<typeof schema>;

const getProfileData = async () => {
  const {
    data: { data },
  } = await API.get(APIRoutes.user.account);
  return data;
};

const ExternalCreditDebitCard = () => {
  const { snack, setSnack } = useToast();
  const { user, restoreProfile } = useAuth();
  const navigate = useNavigate();

  const {
    isSuccess,
    isLoading,
    data: paymentDetails,
  } = useQuery<ProfileProps>(['profile'], () => getProfileData());

  const methods = useForm<FormData>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  const { mutate: updateCardDetails, isLoading: isMutating } = useMutation(
    (payload: any) => API.put(APIRoutes.user.cardDetails, payload),
    {
      onSettled: async (_, error: any) => {
        if (error) {
          const { errors } = error;
          errors.forEach((obj: any) => {
            methods.setError(obj.attribute, { message: obj.message });
          });
        } else {
          restoreProfile();
        }
        setSnack({
          ...snack,
          message: error
            ? `Unable to update card details`
            : 'Your changes have been saved',
          open: true,
          type: error ? 'error' : 'success',
        });
        setTimeout(
          () =>
            navigate(
              routes.main.meetingsRoomsGallery({ spaceId: user?.spaceId }),
            ),
          1000,
        );
      },
    },
  );

  const { handleSubmit, reset } = methods;

  const onSubmit: SubmitHandler<FormData> = async (data: FormData) => {
    const payload = {
      ...data,
      country: get(COUNTRIES, data.country as string),
    };
    updateCardDetails(payload);
  };

  useEffect(() => {
    const cardBillingData = {
      country: paymentDetails?.billingCountryId,
      address1: paymentDetails?.billingAddress,
      city: paymentDetails?.billingCityName,
      zip: paymentDetails?.billingPostCode,
      firstName: user?.firstName,
      lastName: user?.lastName,
    };
    reset(cardBillingData as FormData);
  }, [paymentDetails, user]);

  if (isLoading) {
    return <Loader />;
  }

  if (isSuccess) {
    return (
      <>
        <SectionHeader
          title="Payment Details"
          path={[
            {
              label: 'Meeting Rooms',
            },
            {
              label: 'Payment Details',
            },
          ]}
          elements={<div />}
        />
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <CreditCardDetailsForm isLoading={isLoading || isMutating} />
          </form>
        </FormProvider>
      </>
    );
  }
  return null;
};

export default ExternalCreditDebitCard;
